import { signInWithPopup, onAuthStateChanged, signOut, GoogleAuthProvider, signInWithRedirect } from 'firebase/auth';
import { auth, db } from '../utils/firebase.js';
import { OAuthProvider } from "firebase/auth";
import React, { useEffect, useState } from 'react';
import { addDoc, setDoc, doc, getDoc, updateDoc } from "firebase/firestore";
import { useNavigate } from "react-router-dom";
import { todayMMDDYYYY } from '../utils/getToday.js';
import { toggleModal } from '../utils/uiUtils.js';
import { GoogleLoginButton, YahooLoginButton, MicrosoftLoginButton } from "react-social-login-buttons";
import { postSignInCheck } from '../data/users.js';

function AuthenticationButtons(props) {
    const [user, setUser] = useState(auth.currentUser);
    const navigate = useNavigate();

    const logOut = async () => {
        signOut(auth)
            .then(() => {
                navigate('/');
            })
            .catch((error) => {

            });
    }

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, (user) => {
            setUser(user);
            if (user) {
                sessionStorage.setItem("isLoggedIn", "true");

                var lastLogin = localStorage.getItem("lastLogin");
                if (lastLogin !== todayMMDDYYYY) {
                    const userRef = doc(db, 'users', user.uid);
                    getDoc(userRef).then((result) => {
                        var userSnapshot = result;
                        var docSnapshot = userSnapshot.data();
                        var userData = { lastLogin: new Date() };
                        updateDoc(userRef, userData);
                        localStorage.setItem("lastLogin", todayMMDDYYYY)
                    });
                } else {
                    postSignInCheck(user.uid);
                }
            }
        });

        // Cleanup subscription on unmount
        return () => unsubscribe();
    }, []);

    return (
        <>
            <div className="buttons">
                <button className={"button is-dark mt-0 " + (user ? "" : " is-hidden")} onClick={logOut} id="logoutButton">Log Out</button>
            </div>
        </>
    );
}

export default AuthenticationButtons;