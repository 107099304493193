import { db } from '../utils/firebase.js';
import { doc, getDoc, updateDoc, query, orderBy, collection, where, getDocs } from "firebase/firestore";
import { auth } from '../utils/firebase.js';


export const _getSharedGame = async (trackerId) => {
    var sharedTrackerRef = doc(db, "sharedTrackers", trackerId);
    const sharedTrackerSnap = await getDoc(sharedTrackerRef);
    var sharedTracker = await sharedTrackerSnap.data();

    var gameRef = sharedTracker.tracker;
    const gameSnap = await getDoc(gameRef);
    var game = await gameSnap.data();
    return game;
}

export const _saveSharedUserGameTracker = async (trackerId, game) => {
    var sharedTrackerRef = doc(db, "sharedTrackers", trackerId);
    const sharedTrackerSnap = await getDoc(sharedTrackerRef);
    var sharedTracker = await sharedTrackerSnap.data();

    await updateDoc(sharedTracker.tracker, game);
    return;
}

export const _getSharedGameRef = async (trackerId) => {
    var sharedTrackerRef = doc(db, "sharedTrackers", trackerId);
    const sharedTrackerSnap = await getDoc(sharedTrackerRef);
    var sharedTracker = await sharedTrackerSnap.data();
    return sharedTracker.tracker;
}

export const _getGamesSharedWithUser = async () => {
    var email = auth.currentUser.email;
    const trackerQuery = query(collection(db, "sharedTrackers"), where("sharedWith", "array-contains", email), orderBy("gameId"));
    const querySnapshot = await getDocs(trackerQuery);
    var gameList = [];
    querySnapshot.forEach((doc) => {
        var trackerData = doc.data();
        var tempGame = {
            trackerId: doc.id,
            campaignName: trackerData.campaignName,
            gameName: trackerData.gameFriendlyName,
            gameId: trackerData.gameId,
        };

        gameList.push(tempGame);
    });

    return gameList;
}