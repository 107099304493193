import React, { useState, useEffect } from 'react';
import AuthenticationButtons from '../components/AuthenticationButtons.js';
import logo from '../media/logo.png';
import { onAuthStateChanged } from 'firebase/auth';
import { auth, loggedIn } from '../utils/firebase.js';

function SiteNav(props) {
    const [isLoggedIn, setIsLoggedIn] = useState((auth.currentUser ? true : false));

    useEffect(() => {
        onAuthStateChanged(auth, (user) => {
            if (user) {
                setIsLoggedIn(true);
            } else {
                setIsLoggedIn(false);
            }
        })
    }, []);

    const toggleMenu = () => {
        document.getElementById("bgsNavbar").classList.toggle('is-active');
        document.getElementById("bgsBurgerMenu").classList.toggle('is-active');
    }

    return (
        <>
            <section className="">
                <div className="navbar is-fixed-top has-background-primary" role="navigation" aria-label="main navigation">
                    <div className="navbar-brand">
                        <a href={(loggedIn() ? "/trackingList" : "/")}>
                            <img className="image ml-4 mt-4 mb-2" alt="Board Game Tally Logo" src={logo} ></img>
                        </a>
                        <a role="button" className="navbar-burger" aria-label="menu" aria-expanded="false" data-target="bgsNavbar" id="bgsBurgerMenu" onClick={toggleMenu}>
                            <span aria-hidden="true"></span>
                            <span aria-hidden="true"></span>
                            <span aria-hidden="true"></span>
                        </a>
                    </div>

                    <div className="navbar-menu" id="bgsNavbar">
                        <div className="navbar-end mr-2">
                            <div className="buttons mt-5 mr-3">
                                <button className="navbar-item button is-outlined" onClick={() => { document.getElementById('modal-privacy').classList.add('is-active') }}>Privacy Info</button>
                                <button className="navbar-item button is-outlined" onClick={() => { document.getElementById('modal-whatsnew').classList.add('is-active') }}>What's New!</button>
                            </div>
                            <div className="navbar-item">
                                <AuthenticationButtons />
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <div id="modal-whatsnew" className="modal">
                <div className="modal-background"></div>
                <div className="modal-card">
                    <header className="modal-card-head">
                        <p className="modal-card-title">What's New</p>
                        <button className="delete" aria-label="close" onClick={() => { document.getElementById('modal-whatsnew').classList.remove('is-active') }}></button>
                    </header>
                    <section className="modal-card-body">
                        <div className="is-size-6">
                            <h5 className="title is-5 mb-0">January 12, 2025</h5>
                            <li><span className="has-text-weight-semibold">Soul Raiders Campaign Tracker Is LIVE!</span></li>
                            <li><span className="has-text-weight-bold">I have been working on a monster tableau management for Primal: The Awakening. It is almost done.</span></li>
                            <li><span className="has-text-weight-bold">Cleaned up some UI mess on Isofarian Guard new page. I need to do more UI review and cleanup in general.</span></li>
                        </div>
                        <div className="is-size-6 mt-4">
                            <h5 className="title is-5 mb-0">August 9, 2024</h5>
                            <li><span className="has-text-weight-bold">Monster Hunter World: Adding sharing to Campaigns</span></li>
                            <li><span className="has-text-weight-bold">Primal The Awakening: Fixed bug in display of Valor and Total Defeats.</span></li>
                        </div>
                        <div className="is-size-6 mt-4">
                            <h5 className="title is-5 mb-0">June 8, 2024</h5>
                            <li><span className="has-text-weight-bold">Added Icons to the Primal The Awakening screens</span></li>
                            <li><span className="has-text-weight-bold">Added ability to share the campaign for Primal The Awakening.</span> Shared campaigns allow multiple people to log in and edit the campaign page.
                                Changes should flow through, but press Save if you don't see the changed immediately. Currently Isofarian Guard and Primal The Awakening have Share capability</li>
                        </div>
                    </section>
                    <footer className="modal-card-foot">
                        <button className="button" aria-label="close" onClick={() => { document.getElementById('modal-whatsnew').classList.remove('is-active') }}>Close</button>
                    </footer>
                </div>
            </div>

            <div id="modal-privacy" className="modal">
                <div className="modal-background"></div>
                <div className="modal-card">
                    <header className="modal-card-head">
                        <p className="modal-card-title">Privacy Info</p>
                        <button className="delete" aria-label="close" onClick={() => { document.getElementById('modal-privacy').classList.remove('is-active') }}></button>
                    </header>
                    <section className="modal-card-body">
                        <div className="mb-4">
                            <h5 className="title is-5 mb-0">Why do I have to log in?</h5>
                            <span className="is-size-6">Campaign trackers don't work unless you log in, because we need to save the state between sessions.</span>
                        </div>
                        <div className="mb-4">
                            <h5 className="mb-0 title is-5">Privacy</h5>
                            <span className="is-size-6">
                                <li className="mt-0">This site does not keep any personal data. When you log in with Google, Yahoo or Microsoft we don't store your name, or any information from your account in our database.</li>
                                <li>We save the following information:</li>
                                <div className="is-list ml-5">
                                    <li>The date you first logged in, and the last date you logged in</li>
                                    <li>Whatever information you enter in a campaign tracker for a game</li>
                                    <li>If you share a campaign with someone, their email as you enter it is stored to allow them access</li>
                                </div>
                                <li>The information we do have will never be shared with any third party sites</li>
                                <li>We do not any kind of tracking, or any form of advertising.
                                    If this site ever gets popular enough that I can't afford the cost to host and maintain it, then I will ask for donations or introduce subscriptions rather than rely on advertising</li>
                            </span>
                        </div>
                        <div>
                            <h5 className="title is-5 mb-0">Can I delete my account?</h5>
                            <span className="is-size-6">I have not had time to code this yet. If you wish your account and all information deleted, please send an email BoardGameTally@gmail.com and I will delete it for you.</span>
                        </div>
                    </section>
                    <footer className="modal-card-foot">
                        <button className="button" aria-label="close" onClick={() => { document.getElementById('modal-privacy').classList.remove('is-active') }}>Close</button>
                    </footer>
                </div>
            </div>
        </>
    );
}

export default SiteNav;