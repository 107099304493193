import React from 'react';

function Footer() {
    return (
        <footer className="has-text-centered is-flex-align-items-flex-end mt-auto mb-2">
            <div>
               <hr/>
            </div>
            <div className="mb-4">
                <span>Copyright @2025</span>
                <br />
                <span>Questions, comments, or just want to help with the site? Email: </span>
                <br />
                <span><a href="mailto:BoardGameTally@gmail.com" className="has-text-info">BoardGameTally@gmail.com</a></span>
            </div>

        </footer>
    );
}


export default Footer;


